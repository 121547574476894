.color-picker-popover {
    position: absolute;
    z-index: 2,
}

.cover-color-picker {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}