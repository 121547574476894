.dropdownFilter {
  .dropdownButton {
    display: flex;
    border: 1px solid #3699ff;
    background: none;
    padding: 8px 10px;
    border-radius: 5px;
    color: #3699ff;
    position: relative;
    transition: 0.3s;

    i {
      padding: 0 5px;
      color: #3699ff;
    }

    &:hover {
      background: #3699ff;
      color: #fff;

      i {
        color: #fff;
      }
    }
  }

  .dropdownList {
    position: absolute;
    z-index: 1;
    right: 27px;

    background: #fff;
    box-shadow: 2px 2px 8px #ddd;
    border-radius: 5px;
    margin-top: 10px;
    padding: 10px;
    width: 250px;

    ul {
      list-style: none;
      padding: 0;

      li {
        position: relative;
        z-index: 4;

        margin: 10px 5px;
        display: flex;
        align-items: center;
        color: #666;
        cursor: pointer;

        &:hover {
          color: #3699ff;
        }
      }

      i {
        margin: 0px 5px;
        color: #3699ff;
      }
    }
    .react-datepicker-popper {
      z-index: 4;
    }
  }
  .coverDropdown {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
