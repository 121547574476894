small {
  text-align: center !important;
  color: #f00;
  line-height: 0.1;
}

.required {
  border: 1px solid #f00 !important;
}

.erro-login {
  display: flex;
  justify-content: center;
}

.reennviar-codigo {
  color: #60697b;
  a {
    color: #6bbea3;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.pass-wrapper {
  position: relative;
}

.eye-icon {
  position: absolute;
  top: 35%;
  right: 15px;
}
