.cardHome {
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  min-height: 300px;
}

.card-home-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: #3699ff;

  i {
    color: #3699ff;
  }
}

.card-home-body {
  padding: 10px 0px;

  .hide-button {
    cursor: pointer;
  }

  .key {
    font-size: 11px;
  }

  .payments {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .net-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .value {
      font-size: 18px;
    }
  }

  .net-sale {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .tariffsFees {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 10px;
    color: #f00;
  }

  .obs {
    font-size: 10px;
    color: #999;
  }

  .hide-value {
    background: #ccc;
    color: #ccc;
    margin: 2px;
    width: 50%;
    height: 15px;
    // font-size: 18px;
  }
}
